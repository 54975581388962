import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Spinner, Card, CardBody, Badge, UncontrolledPopover, PopoverBody,
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DateIntervalDropdown from '../../shared/components/DateIntervalDropdown';
import moment from "moment";
import { BsBoxArrowUpRight, BsPersonFill } from 'react-icons/bs';
import { translate_text } from "../../helpers/translation/utils";
import Translate from "../../shared/components/Translate";
import { FlagIcon } from 'react-flag-kit';
import {
  amazing,
  bad,
  great,
  ok,
  terrible,
} from '../../assets/images';
import { momentDateToISOFormatConversion } from "../../helpers/utils";
import './style.css';
import dashboardActions from "../../redux/dashboard/actions";

const ratingMap = {
  0 : { emoji: null, text: "" },
  1 : { emoji: terrible, text: "Terrible" },
  2 : { emoji: bad, text: "Bad" },
  3 : { emoji: ok, text: "OK" },
  4 : { emoji: great, text: "Great" },
  5 : { emoji: amazing, text: "Amazing" }
}

const {
  dashboardListOperators,
  dashboardExportOperators,
} = dashboardActions;

class Surveys extends Component {
  state = {
    sizePerPage: 10,
    loading: true,
    sortOrder: 'desc',
    sortField: 'id',
    selectedInterval: {
      value: `${moment().startOf('month').format("YYYYMMDD")}-${moment().format("YYYYMMDD")}`,
      label: <Translate>This Month</Translate>
    },
    showIntervalDropdown: false,
    range: {
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      key: 'selection'
    },
  }

  componentDidMount = () => {
    const today = moment().format("YYYYMMDD");
    let start_month = moment().startOf('month').format("YYYYMMDD");
    const interval = `${start_month}-${today}`;

    let data = {
      interval: interval,
      is_survey: true,
    }
    this.props.dashboardListOperators(data);

  }

  toggleIntervalDropdown = () => {
    this.setState({
      showIntervalDropdown: !this.state.showIntervalDropdown
    })
  }

  customRangesChange = (ranges) => {
    this.setState({
      range: ranges['selection'],
    })
  }

  selectInterval = (selected) => {
    this.setState({ selectedInterval: selected })
    this.searchData({
      interval: selected.value,
    })
  }

  applyRange = () => {
    const { range } = this.state;

    const start = moment(range.startDate).format("YYYYMMDD");
    const end = moment(range.endDate).format("YYYYMMDD");
    this.selectInterval({value: `${start}-${end}`, label: <Translate>Custom</Translate>});
  }

  createTimeDropdown = () => {
    const today = moment().format("YYYYMMDD");
    const start_month = moment().startOf('month').format("YYYYMMDD");
    const start_year = moment().startOf('year').format("YYYYMMDD");
    const start_quarter = moment().startOf('quarter').format("YYYYMMDD");
    const end_quarter = moment().endOf('quarter').format("YYYYMMDD");

    const options = [
      {value: `${start_month}-${today}`, label: <Translate>This Month</Translate>},
      {value: `${start_quarter}-${end_quarter}`, label: <Translate>This Quarter</Translate>},
      {value: `${start_year}-${today}`, label: <Translate>This Year</Translate>},
    ]
    return options;
  }

  displayReccomendationRating = (cell) => {
    var data = null;
    if(!cell){ return null }
    let color = "";
    if(cell > 9) {
      color = "success";
      cell = 10;
    } else if( cell >= 6 && cell <= 9) {
      color = "warning";
      cell = 7;
    } else if(cell <= 5 ) {
      color = "danger";
      cell = 5;
    }
    if(color){
      data = <Badge color={color} className="badge-nps">{cell}</Badge>
    }
    return data;
  }

  generateColumns = () => {
    const { userData } = this.props;

    const columns = [
      {
        dataField: 'created_at',
        text: translate_text("Date/time", userData?.settings?.language),
        sort: true,
        formatter: (cell, row) => (
          momentDateToISOFormatConversion(cell, 'MM-DD-YYYY HH:mm')
        )
      },
      {
        dataField: 'customer_name',
        text: translate_text("Customer", userData?.settings?.language),
        sort: true,
        formatter: (cell, row) => (
          <div className="profile-customer">
            {row.customer_avatar ?
            <span
              className="avatar"
              style={{ backgroundImage: `url(${row.customer_avatar})` }}
            >
            </span>
            :
            <span className="avatar">
              <BsPersonFill />
            </span>
            }
            {row.customer_country &&
            <FlagIcon
              size={24}
              code={row.customer_country}
              className="country-img"
            />
            }
            <span className="name">{cell}</span>
          </div>
        )
      },
      {
        dataField: 'reccomendation_rating',
        text: translate_text("NPS Overall", userData?.settings?.language),
        sort: true,
        headerClasses: 'text-center',
        classes: 'text-center',
        formatter: (cell, row) => (
          <div>{this.displayReccomendationRating(cell)}</div>
        )
      },
      {
        dataField: 'rating',
        text: translate_text("Rating", userData?.settings?.language),
        sort: true,
        headerClasses: 'text-center',
        classes: 'text-center',
        formatter: (cell, row) => (
          <div className="rating-emoji">
            <img
              src={ratingMap[cell].emoji}
              alt={ratingMap[cell].text}
              title={ratingMap[cell].text}
            />
            <span className="ms-1">
              {cell ? `${ratingMap[cell].text}` : null}
              {cell ? `(${cell})` : null}
            </span>
          </div>
        )
      },
      {
        dataField: 'comment',
        text: translate_text("Comment", userData?.settings?.language),
        sort: false,
        headerClasses: 'text-center',
        classes: 'text-center',
        formatter: (cell, row) => (
          cell ?
          <React.Fragment>
            <span
              id={`popover-${row.id}`}
              className="comment text-truncate"
            >{cell}</span>
            <UncontrolledPopover
              target={`popover-${row.id}`}
              placement="top"
              trigger="hover"
            >
              <PopoverBody>
                {cell}
              </PopoverBody>
            </UncontrolledPopover>
          </React.Fragment>
          : <small className="text-muted">N/A</small>
        )
      },
      {
        dataField: 'link',
        text: translate_text("Chat", userData?.settings?.language),
        sort: false,
        formatter: (cell, row) => (
          <a
            href={`https://app.crisp.chat/website/${row.website_id}/inbox/${row.session_id}/`}
            target="_blank"
            rel="noreferrer"
          ><Translate>Read</Translate> <BsBoxArrowUpRight size="11" style={{ verticalAlign: "baseline" }} /></a>
        )
      },
    ]
    return columns;
  }

  searchData = (data) => {
    const {
      sizePerPage,
      page,
      sortField,
      sortOrder,
      selectedInterval,
    } = this.state;

    let payload = {
      count: sizePerPage,
      page: page,
      sort_field: sortField,
      sort: sortOrder,
      interval: data?.interval ? data.interval : selectedInterval.value,
      is_survey: true,
    }

    this.props.dashboardListOperators(payload);
  }

  handleTableChange = (type, newState) => {
    this.setState({
      sizePerPage: newState.sizePerPage,
      sortField: newState.sortField,
      sortOrder: newState.sortOrder,
      page: newState.page,
    });

    let payload = {
      count: newState.sizePerPage,
      page: newState.page,
      sort_field: newState.sortField,
      sort: newState.sortOrder,
      interval: this.state.selectedInterval.value,
      is_survey: true,
    }

    this.props.dashboardListOperators(payload);
  }

  dashboardExportOperators = () => {
    const {
      selectedInterval,
    } = this.state;

    let payload = {
      interval: selectedInterval.value,
      is_survey: true,
    }

    this.props.dashboardExportOperators(payload);
  }

  render() {
    const {
      operators,
      operators_loading
    } = this.props;

    return (
      <div className="main-content view-surveys">
        <div className="page-header">
          <h2 className="page-title"><Translate>My Surveys</Translate></h2>
          <div className="controls">
            <DateIntervalDropdown
              isOpen={this.state.showIntervalDropdown}
              toggle={this.toggleIntervalDropdown}
              selected={this.state.selectedInterval}
              options={this.createTimeDropdown()}
              onSelect={this.selectInterval}
              showCustom
              customRanges={[this.state.range]}
              customRangesChange={this.customRangesChange}
              applyRange={this.applyRange}
              rightDropdown
            />
          </div>
        </div>
        <div className="page-content">
          <Card>
            <CardBody className="table-wrapper">
              {operators_loading &&
                <div className="card-loading">
                  <div className="loading-indicator">
                    <Spinner color="secondary" /> <span>Loading...</span>
                  </div>
                </div>
              }
              <BootstrapTable
                remote
                keyField="id"
                columns={this.generateColumns()}
                bordered={false}
                classes="table-all-surveys"
                wrapperClasses="table-responsive"
                data={operators?.items ? operators.items : []}
                pagination={paginationFactory({
                  page: operators?.current_page ? operators?.current_page : 1,
                  sizePerPage: this.state.sizePerPage,
                  totalSize: operators?.total
                    ? operators?.total
                    : operators?.items
                      ? operators?.items.length
                      : 0
                })}
                sort={
                  {
                    dataField: this.state.sortField,
                    order: this.state.sortOrder,
                  }
                }
                onTableChange={this.handleTableChange}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
    operators: state.Dashboard.get("operators"),
    operators_loading: state.Dashboard.get("operators_loading"),
  }),
  {
    dashboardListOperators,
    dashboardExportOperators,
  }
)(Surveys);
