import { all, takeLatest, put, fork, call } from "redux-saga/effects";
//import { push } from "react-router-redux";
import { apiCalls } from "../../helpers/api/utils";
import { apiEndpoints } from "../../config/api";
import actions from "./actions";
import { sdMessage } from "../../helpers/utils";
import { commonSaga, default_config } from "../../helpers/redux/utils";

export function* userGetData() {
	yield takeLatest(actions.USER_GET_DATA, function*(payload) {
		try {
			const res = yield call(apiCalls.get, apiEndpoints.user.user, null);
			if (res.data.error) {
				yield put(actions.userGetDataError());
			} else {
				yield put(actions.userGetDataSuccess(res.data));
        const { email, username } = res.data.data;
        window.CRISP_TOKEN_ID = username;
        window.$crisp.push(["do", "session:reset"]);
        window.$crisp.push(["set", "user:email", [email]]);
        window.$crisp.push(["set", "user:nickname", [username]]);
			}
		} catch (error) {
				yield put(actions.userGetDataError());
		}
	})
}

export function* userSaveData() {
	yield takeLatest(actions.USER_SAVE_DATA, function*(payload) {
		try {
			const res = yield call(apiCalls.put, apiEndpoints.user.user, payload.data);
			if (res.data.error) {
				yield put(actions.userSaveDataError());
			} else {
				yield put(actions.userSaveDataSuccess(res.data));
			}
		} catch (error) {
				yield put(actions.userSaveDataError());
		}
	})
}

export function* userSetData() {
  yield takeLatest(actions.USER_SET_DATA, function*(payload) {
    const { data } = payload;
    yield put(actions.userSetDataSuccess(data));
  })
}

export function* userPasswordReset() {
	yield takeLatest(actions.USER_PASSWORD_RESET, function*(payload) {
		try {
			const res = yield call(apiCalls.post, apiEndpoints.user.password_reset, payload.data);
			if (res.data.error) {
				yield put(actions.userPasswordResetError());
				sdMessage(res.data.error, "error");
			} else {
				yield put(actions.userPasswordResetSuccess(res.data));
				sdMessage(res.data.message, "success");
			}
		} catch (error) {
				yield put(actions.userPasswordResetError());
		}
	})
}

export function* userSlackAuthStart() {
  yield commonSaga(
    actions.USER_SLACK_AUTH_START,
    apiCalls.get,
    apiEndpoints.user.slack_auth,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* userSlackAuthDance() {
  yield commonSaga(
    actions.USER_SLACK_AUTH_DANCE,
    apiCalls.post,
    apiEndpoints.user.slack_auth,
    {
      ...default_config,
      ...{
        success_show: false
      }
    }
  );
}

export function* userUpdateSettings() {
  yield commonSaga(
    actions.USER_UPDATE_SETTINGS,
    apiCalls.put,
    apiEndpoints.user.settings,
    {
      ...default_config,
      ...{
        success_show: false
      }
    }
  );
}

export function* userSlackGetChannels() {
  yield commonSaga(
    actions.USER_SLACK_GET_CHANNELS,
    apiCalls.get,
    apiEndpoints.user.slack_channel,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* userSlackUpdateChannel() {
  yield commonSaga(
    actions.USER_SLACK_UPDATE_CHANNEL,
    apiCalls.put,
    apiEndpoints.user.slack_channel,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* userSlackAuthDelete() {
  yield commonSaga(
    actions.USER_SLACK_AUTH_DELETE,
    apiCalls.delete,
    apiEndpoints.user.slack_auth,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* userSlackGetUsers() {
  yield commonSaga(
    actions.USER_SLACK_GET_USERS,
    apiCalls.get,
    apiEndpoints.user.slack_user,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* userSlackUpdateUser() {
  yield commonSaga(
    actions.USER_SLACK_UPDATE_USER,
    apiCalls.put,
    apiEndpoints.user.slack_user,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* userWebsiteUpdateSegmentation() {
  yield commonSaga(
    actions.USER_WEBSITE_SEGMENTATION_UPDATE,
    apiCalls.put,
    apiEndpoints.user.segmentation,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* userWebsiteUpdate() {
  yield commonSaga(
    actions.USER_WEBSITE_UPDATE,
    apiCalls.put,
    apiEndpoints.website.website,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* userEmployeeAdd() {
  yield commonSaga(
    actions.USER_EMPLOYEE_ADD,
    apiCalls.post,
    apiEndpoints.user.employee,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* userEmployeeUpdate() {
  yield commonSaga(
    actions.USER_EMPLOYEE_UPDATE,
    apiCalls.put,
    apiEndpoints.user.employee,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* userEmployeeLoad() {
  yield commonSaga(
    actions.USER_EMPLOYEE_LOAD,
    apiCalls.get,
    apiEndpoints.user.employee,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* userEmployeesLoad() {
  yield commonSaga(
    actions.USER_EMPLOYEES_LOAD,
    apiCalls.get,
    apiEndpoints.user.employees,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* userWebsiteDeleteSegmentation() {
  yield commonSaga(
    actions.USER_WEBSITE_SEGMENTATION_DELETE,
    apiCalls.delete,
    apiEndpoints.user.segmentation,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* userAlertDismiss() {
  yield commonSaga(
    actions.USER_ALERT_DISMISS,
    apiCalls.put,
    apiEndpoints.user.alert_dismiss,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

/*
export function* userSearch() {
  yield commonSaga(
    actions.USER_SEARCH,
    apiCalls.post,
    apiEndpoints.admin.search_users,
    {...default_config, ...{success_show: false}}
  );
}

export function* userSearchSingle() {
  yield commonSaga(
    actions.USER_SEARCH_SINGLE,
    apiCalls.get,
    apiEndpoints.admin.search_users_single,
    {...default_config, ...{success_show: false}}
  );
}
*/

export default function* authSaga() {
	yield all([
		fork(userGetData),
		fork(userSaveData),
		fork(userSetData),
		fork(userPasswordReset),
    fork(userSlackAuthStart),
    fork(userSlackAuthDance),
    fork(userUpdateSettings),
    fork(userSlackGetChannels),
    fork(userSlackUpdateChannel),
    fork(userSlackAuthDelete),
    fork(userSlackGetUsers),
    fork(userSlackUpdateUser),
    fork(userWebsiteUpdateSegmentation),
    fork(userWebsiteUpdate),
    fork(userEmployeeAdd),
    fork(userEmployeeUpdate),
    fork(userEmployeeLoad),
    fork(userEmployeesLoad),
    fork(userWebsiteDeleteSegmentation),
    fork(userAlertDismiss),
    //fork(userSearch),
    //fork(userSearchSingle),
	]);
}
