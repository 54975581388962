import { Map } from "immutable";
import actions from "./actions";

const initState = new Map({
	userData: {
		username: null,
	},
  updating_user_settings: false,
  reset_password_full_working: false,
  reset_password_full_executed: false,
  reset_password_full_validate_working: false,
  reset_password_full_validate_executed: false,
  user_search_data: {},
  user_search_data_loading: false,
  user_search_data_single: {},
  user_search_data_single_loading: false,
  slack_auth_loading: false,
  user_settings_loading: false,
  slack_channels: [],
  slack_channels_loading: false,
  slack_users: [],
  slack_users_loading: false,
  website_segmentation_loading: false,
  website_loading: false,
  employees: [],
  employees_loading: false,
  employee: {},
});

export default function userReducer(state = initState, action) {
  switch (action.type) {

    case "USER_ALERT_DISMISS":
      return state
        .set("user_settings_loading", true)
    case "USER_ALERT_DISMISS_SUCCESS":
      var alert_dismiss_user = state.get("userData");
      alert_dismiss_user.top_alert = null;
      return state
        .set("userData", {...alert_dismiss_user})
        .set("user_settings_loading", false)
    case "USER_ALERT_DISMISS_FAILED":
      return state
        .set("user_settings_loading", false)

    case "USER_WEBSITE_SEGMENTATION_DELETE":
      return state
        .set("website_segmentation_loading", true)
    case "USER_WEBSITE_SEGMENTATION_DELETE_SUCCESS":
      var user_slack_segmentation_delete = state.get("userData");
      user_slack_segmentation_delete.website.segmentation_deleted = action.data.data.segmentation_deleted;
      user_slack_segmentation_delete.website.segmentation_deleted_list = action.data.data.segmentation_deleted_list;
      return state
        .set("userData", {...user_slack_segmentation_delete})
        .set("website_segmentation_loading", false)
    case "USER_WEBSITE_SEGMENTATION_DELETE_FAILED":
      return state
        .set("website_segmentation_loading", false)

    case "USER_EMPLOYEES_LOAD":
      return state
        .set("employees", [])
        .set("employees_loading", true)
    case "USER_EMPLOYEES_LOAD_SUCCESS":
      return state
        .set("employees", [...action.data.data])
        .set("employees_loading", false)
    case "USER_EMPLOYEES_LOAD_FAILED":
      return state
        .set("employees", [])
        .set("employees_loading", false)

    case "USER_EMPLOYEE_LOAD":
      return state
        .set("employees", [])
        .set("employees_loading", true)
    case "USER_EMPLOYEE_LOAD_SUCCESS":
      return state
        .set("employee", {...action.data.data})
        .set("employees_loading", false)
    case "USER_EMPLOYEE_LOAD_FAILED":
      return state
        .set("employees", [])
        .set("employees_loading", false)

    case "USER_EMPLOYEE_UPDATE":
      return state
        .set("employees_loading", true)
    case "USER_EMPLOYEE_UPDATE_SUCCESS":
      let user_employees_update = [];
      state.get("employees").forEach(item => {
        if(item.id === action.data.data.id){
          if(!action.data.data.deleted){
            user_employees_update.push(action.data.data);
          }
        } else {
          user_employees_update.push(item);
        }
      });
      return state
        .set("employees", [...user_employees_update])
        .set("employees_loading", false)
    case "USER_EMPLOYEE_UPDATE_FAILED":
      return state
        .set("employees_loading", false)

    case "USER_EMPLOYEE_ADD":
      return state
        .set("employees_loading", true)
    case "USER_EMPLOYEE_ADD_SUCCESS":
      var user_employees_add = state.get("employees");
      user_employees_add = [...user_employees_add, action.data.user_data];
      return state
        .set("employees", user_employees_add)
        .set("employees_loading", false)
    case "USER_EMPLOYEE_ADD_FAILED":
      return state
        .set("employees_loading", false)

    case "USER_WEBSITE_UPDATE":
      return state
        .set("website_loading", true)
    case "USER_WEBSITE_UPDATE_SUCCESS":
      var user_website_update = state.get("userData");
      user_website_update.website = {...action.data.data};
      return state
        .set("userData", {...user_website_update})
        .set("website_loading", false)
    case "USER_WEBSITE_UPDATE_FAILED":
      return state
        .set("website_loading", false)

    case "USER_WEBSITE_SEGMENTATION_UPDATE":
      return state
        .set("website_segmentation_loading", true)
    case "USER_WEBSITE_SEGMENTATION_UPDATE_SUCCESS":
      var user_slack_segmentation_update = state.get("userData");
      user_slack_segmentation_update.website.segmentation = action.data.data;
      return state
        .set("userData", {...user_slack_segmentation_update})
        .set("website_segmentation_loading", false)
    case "USER_WEBSITE_SEGMENTATION_UPDATE_FAILED":
      return state
        .set("website_segmentation_loading", false)

    case "USER_SLACK_UPDATE_USER":
      return state
        .set("slack_users_loading", true)
    case "USER_SLACK_UPDATE_USER_SUCCESS":
      var user_slack_update_user = state.get("userData");
      user_slack_update_user.website = {
        ...user_slack_update_user.website,
        ...action.data.data
      }
      return state
        .set("userData", {...user_slack_update_user})
        .set("slack_users_loading", false)
    case "USER_SLACK_UPDATE_USER_FAILED":
      return state
        .set("slack_users_loading", false)

    case "USER_SLACK_GET_USERS":
      return state
        .set("slack_users_loading", true)
    case "USER_SLACK_GET_USERS_SUCCESS":
      return state
        .set("slack_users", [...action.data])
        .set("slack_users_loading", false)
    case "USER_SLACK_GET_USERS_FAILED":
      return state
        .set("slack_users_loading", false)

    case "USER_SLACK_AUTH_DELETE":
      return state
        .set("slack_auth_loading", true)
    case "USER_SLACK_AUTH_DELETE_SUCCESS":
      var user_slack_delete = state.get("userData");
      user_slack_delete.website.slack_access_token = false;
      return state
        .set("userData", {...user_slack_delete})
        .set("slack_auth_loading", false)
    case "USER_SLACK_AUTH_DELETE_FAILED":
      return state
        .set("slack_auth_loading", false)

    case "USER_SLACK_UPDATE_CHANNEL":
      return state
        .set("slack_channels_loading", true)
    case "USER_SLACK_UPDATE_CHANNEL_SUCCESS":
      var user_slack_update = state.get("userData");
      user_slack_update.website = {
        ...user_slack_update.website,
        ...action.data.data
      }
      return state
        .set("userData", {...user_slack_update})
        .set("slack_channels_loading", false)
    case "USER_SLACK_UPDATE_CHANNEL_FAILED":
      return state
        .set("slack_channels_loading", false)

    case "USER_SLACK_GET_CHANNELS":
      return state
        .set("slack_channels_loading", true)
    case "USER_SLACK_GET_CHANNELS_SUCCESS":
      return state
        .set("slack_channels", [...action.data])
        .set("slack_channels_loading", false)
    case "USER_SLACK_GET_CHANNELS_FAILED":
      return state
        .set("slack_channels_loading", false)

    case "USER_UPDATE_SETTINGS":
      var user_settings = state.get("userData");
      user_settings.settings = {
        ...user_settings.settings,
        ...action.data
      }
      return state
        .set("userData", {...user_settings})
        .set("user_settings_loading", true)
    case "USER_UPDATE_SETTINGS_SUCCESS":
      var user_settings_success = state.get("userData");
      user_settings_success.settings = {
        ...user_settings_success.settings,
        ...action.data.data
      }
      return state
        .set("userData", {...user_settings_success})
        .set("user_settings_loading", false)
    case "USER_UPDATE_SETTINGS_FAILED":
      return state
        .set("user_settings_loading", false)

    case "USER_SLACK_AUTH_START":
      return state
        .set("slack_auth_loading", true)
    case "USER_SLACK_AUTH_START_SUCCESS":
      return state
        .set("slack_auth_loading", false)
    case "USER_SLACK_AUTH_START_FAILED":
      return state
        .set("slack_auth_loading", false)

    case "USER_SLACK_AUTH_DANCE":
      return state
        .set("slack_auth_loading", true)
    case "USER_SLACK_AUTH_DANCE_SUCCESS":
      return state
        .set("slack_auth_loading", false)
    case "USER_SLACK_AUTH_DANCE_FAILED":
      return state
        .set("slack_auth_loading", false)

		case "USER_SEARCH_SINGLE":
			return state
				.set("user_search_data_single", {})
				.set("user_search_data_single_loading", true)
		case "USER_SEARCH_SINGLE_SUCCESS":
			return state
				.set("user_search_data_single", {...action.data.data})
				.set("user_search_data_single_loading", false)
		case "USER_SEARCH_SINGLE_FAILED":
			return state
				.set("user_search_data_single", {})
				.set("user_search_data_single_loading", false)

		case "USER_SEARCH":
			return state
				.set("user_search_data", {})
				.set("user_search_data_loading", true)
		case "USER_SEARCH_SUCCESS":
			return state
				.set("user_search_data", {...action.data.data})
				.set("user_search_data_loading", false)
		case "USER_SEARCH_FAILED":
			return state
				.set("user_search_data", {})
				.set("user_search_data_loading", false)

		case actions.USER_PASSWORD_RESET:
			return state
				.set("user_data_saving", true)
		case actions.USER_PASSWORD_RESET_SUCCESS:
			return state
				.set("user_data_saving", false)
		case actions.USER_PASSWORD_RESET_ERROR:
			return state
				.set("user_data_saving", false)

		case actions.USER_SAVE_DATA:
			return state
				.set("user_data_saving", true)
		case actions.USER_SAVE_DATA_SUCCESS:
			return state
				.set("userData", Object.assign({}, action.data.data))
				.set("user_data_saving", false)
		case actions.USER_SAVE_DATA_ERROR:
			return state
				.set("user_data_saving", false)

		case actions.USER_SET_DATA_SUCCESS:
			return state
				.set("userData", Object.assign({}, action.data))

		case actions.USER_GET_DATA_SUCCESS:
			return state
				.set("userData", Object.assign({}, action.data.data))
				.set("data_up_to_date", true)

		case actions.USER_GET_DATA_ERROR:
			return state
				.set("data_up_to_date", false)

		default:
			return state;
  }
}
