import React, { Component } from 'react';
import { connect } from "react-redux";
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { Card, CardBody, CardTitle, FormGroup, Input, Label, Col, Row, CardFooter, Button, FormFeedback, InputGroup, InputGroupText } from 'reactstrap';
import userActions from "../../redux/user/actions";
import Select from 'react-select';
import { FlagIcon } from 'react-flag-kit';
import Translate from "../../shared/components/Translate";

const lang_options = [
  {
    value: "US",
    label: <span><FlagIcon code="US" size={16} />&nbsp; <Translate>English</Translate></span>
  },
  {
    value: "RS",
    label: <span><FlagIcon code="RS" size={16} />&nbsp; <Translate>Serbian</Translate></span>
  },
  {
    value: "FR",
    label: <span><FlagIcon code="FR" size={16} />&nbsp; <Translate>French</Translate></span>
  },
  {
    value: "DE",
    label: <span><FlagIcon code="DE" size={16} />&nbsp; <Translate>German</Translate></span>
  },
  {
    value: "PT",
    label: <span><FlagIcon code="PT" size={16} />&nbsp; <Translate>Portuguese</Translate></span>
  },
  {
    value: "ES",
    label: <span><FlagIcon code="ES" size={16} />&nbsp; <Translate>Spanish</Translate></span>
  },
  {
    value: "AE",
    label: <span><FlagIcon code="AE" size={16} />&nbsp; <Translate>Arabic</Translate></span>
  },
  {
    value: "KR",
    label: <span><FlagIcon code="KR" size={16} />&nbsp; <Translate>Korean</Translate></span>
  },
]

const {
  userSaveData,
  userPasswordReset,
  userUpdateSettings,
} = userActions;

class MyAccount extends Component {
  state = {
    old_pw: "",
    new_pw: "",
    new_pw_confirm: "",
    has_min_chars: false,
    has_uppercase: false,
    has_number: false,
    has_symbol: false,
    show_old_pw: false,
    show_new_pw: false,
    first_name: "",
    last_name: "",
  }

  toggleShowPassword = (name) => {
    this.setState({ [name]: !this.state[name] })
  }

  passwordRequirementsCheck = (value) => {
    this.setState({ new_pw: value })

    // check min chars
    if(value.length >= 8) {
      this.setState({ has_min_chars: true })
    } else {
      this.setState({ has_min_chars: false })
    }

    // check if has uppercase
    if(value.toLowerCase() !== value) {
      this.setState({ has_uppercase: true })
    } else {
      this.setState({ has_uppercase: false })
    }

    // check if has number
    const matches = value.match(/\d+/g);
    if (matches !== null) {
      this.setState({ has_number: true })
    } else {
      this.setState({ has_number: false })
    }

    // check if has symbol
    const matches2 = value.match(/[!-/:-@[-`{-~]/gm);
    if (matches2 !== null) {
      this.setState({ has_symbol: true })
    } else {
      this.setState({ has_symbol: false })
    }
  }

  userSaveData = () => {
    const { first_name, last_name } = this.state;
    const { userData } = this.props;

    const data = {
      first_name: first_name ? first_name : userData?.first_name,
      last_name: last_name ? last_name : userData?.last_name,
    }
    this.props.userSaveData(data);
    this.setState({
      first_name: "",
      last_name: "",
    });
  }

  checkPW = () => {
    const {
      has_min_chars,
      has_uppercase,
      has_number,
      has_symbol,
    } = this.state;
    return has_min_chars && has_uppercase && has_number && has_symbol;
  }

  userPasswordReset = () => {
    const {
      old_pw,
      new_pw,
      new_pw_confirm,
    } = this.state;
    if(new_pw !== new_pw_confirm){ return false }
    const data = {
      new_password: new_pw,
      old_password: old_pw,
    }
    this.props.userPasswordReset(data);
  }

  updateLanguage = (language) => {
    const data = {
      language: language,
    }
    this.props.userUpdateSettings(data);
  }


  render() {
    const {
      old_pw,
      new_pw,
      new_pw_confirm,
      has_min_chars,
      has_uppercase,
      has_number,
      has_symbol,
      show_old_pw,
      show_new_pw,
      first_name,
      last_name,
    } = this.state;

    const { userData, user_data_saving } = this.props;

    return (
      <div className="main-content view-my-account">
        <div className="page-content">
          <div className="settings-area">
            <div className="settings-content">
              <div className="my-account">
                <Card>
                  <CardBody>
                    <CardTitle><Translate>Account Information</Translate></CardTitle>
                    <FormGroup row>
                      <Col md={6}>
                        <Label><Translate>Username</Translate></Label>
                        <Input defaultValue={userData?.username} readOnly />
                      </Col>
                      <Col md={6}>
                        <Label>Email</Label>
                        <Input type="email" defaultValue={userData?.email} readOnly />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col md={6}>
                        <Label><Translate>First Name</Translate></Label>
                        <Input
                          defaultValue={userData?.first_name}
                          onChange={(e) => this.setState({ first_name: e.target.value})}
                        />
                      </Col>
                      <Col md={6}>
                        <Label><Translate>Last Name</Translate></Label>
                        <Input
                          defaultValue={userData?.last_name}
                          onChange={(e) => this.setState({ last_name: e.target.value})}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col md={6}>
                        <Label><Translate>Language</Translate></Label>
                        <Select
                          options={lang_options}
                          defaultValue={lang_options[0]}
                          isSearchable={false}
                          onChange={(e) => this.updateLanguage(e.value)}
                          value={userData?.settings?.language ? lang_options.find(item => item.value === userData?.settings?.language) : null}
                        />
                      </Col>
                    </FormGroup>
                  </CardBody>
                  <CardFooter>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={(e) => this.userSaveData()}
                      disabled={
                        user_data_saving
                          || (!first_name && !last_name)
                      }
                    ><Translate>Save changes</Translate></Button>
                  </CardFooter>
                </Card>

                <Card>
                  <CardBody>
                    <CardTitle><Translate>Change Password</Translate></CardTitle>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label><Translate>Old Password</Translate></Label>
                          <InputGroup>
                            <Input
                              type={show_old_pw ? "text" : "password"}
                              value={old_pw}
                              onChange={e => this.setState({ old_pw: e.target.value })}
                              invalid={!old_pw}
                            />
                            <InputGroupText
                              className="cursor-pointer"
                              onClick={() => this.toggleShowPassword("show_old_pw")}
                            >
                              {show_old_pw ? <BsEyeSlash /> : <BsEye /> }
                            </InputGroupText>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label><Translate>New Password</Translate></Label>
                          <InputGroup>
                            <Input
                              type={show_new_pw ? "text" : "password"}
                              value={new_pw}
                              onChange={e => this.passwordRequirementsCheck(e.target.value)}
                              invalid={!this.checkPW()}
                            />
                            <InputGroupText
                              className="cursor-pointer"
                              onClick={() => this.toggleShowPassword("show_new_pw")}
                            >
                              {show_new_pw ? <BsEyeSlash /> : <BsEye /> }
                            </InputGroupText>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label><Translate>Confirm New Password</Translate></Label>
                          <InputGroup>
                            <Input
                              type={show_new_pw ? "text" : "password"}
                              value={new_pw_confirm}
                              onChange={e => this.setState({ new_pw_confirm: e.target.value })}
                              invalid={new_pw_confirm !== new_pw || !this.checkPW()}
                            />
                            <InputGroupText
                              className="cursor-pointer"
                              onClick={() => this.toggleShowPassword("show_new_pw")}
                            >
                              {show_new_pw ? <BsEyeSlash /> : <BsEye /> }
                            </InputGroupText>
                          </InputGroup>
                          <FormFeedback>
                            {new_pw_confirm !== new_pw ?
                                "Passwords need to match" : null}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <h6><Translate>Password Tips</Translate>:</h6>
                        <ul className="password-tips-check">
                          <li className={has_min_chars ? "check" : ""}><Translate>Minimum 8 characters</Translate></li>
                          <li className={has_uppercase ? "check" : ""}><Translate>Contain at least 1 uppercase letter</Translate></li>
                          <li className={has_number ? "check" : ""}><Translate>Contain at least 1 number</Translate></li>
                          <li className={has_symbol ? "check" : ""}><Translate>Contain at least 1 symbol</Translate></li>
                        </ul>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={(e) => this.userPasswordReset()}
                      disabled={
                        user_data_saving
                          || !this.checkPW()
                          || new_pw !== new_pw_confirm
                          || !old_pw
                      }
                    ><Translate>Change password</Translate></Button>
                  </CardFooter>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
    user_data_saving: state.User.get("user_data_saving"),
  }),
  {
    userSaveData,
    userPasswordReset,
    userUpdateSettings,
  }
)(MyAccount);
