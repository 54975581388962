import moment from 'moment-timezone'
import { toast } from "react-toastify";

/**
 * self destructed message positions
 */
export const sdMessagePositions = {
	"TOP_CENTER": toast.POSITION.TOP_CENTER,
	"TOP_RIGHT": toast.POSITION.TOP_RIGHT,
	"BOTTOM_RIGHT": toast.POSITION.BOTTOM_RIGHT,
	"BOTTOM_CENTER": toast.POSITION.BOTTOM_CENTER,
	"BOTTOM_LEFT": toast.POSITION.BOTTOM_LEFT,
	"TOP_LEFT": toast.POSITION.TOP_LEFT,
}

/**
 * self destructed message
 * @param {string} message Message to display
 * @param {string} position Position
 * @param {string} type Type of message
 */
export const sdMessage = (message, message_type = "error", position = "BOTTOM_CENTER") => {
	position = sdMessagePositions[position];
	if (!position) {
		position = sdMessagePositions["BOTTOM_CENTER"];
	}
	toast[message_type](message, { position })
}

export const getValueFromObject = (object, path) => {
	const paths = path.split('.');
	while (paths.length > 1) {
		object = object[paths.shift()];
		if(!object){
			return null;
		}
	}
	return object[paths.shift()];
}

export const momentDateToISOFormatConversion = (date, format="LLL") => {
  if (date) {
    return moment(date).format(format)
  } else {
    return moment().format(format)
  }
}

export const momentTSDateToISOFormatConversion = (date, format="LLL", tz=null) => {
  let m = null;
  if (date) {
      m = moment.unix(date)
  } else {
      m = moment()
  }
  if(tz){
    m = m.tz(tz)
  }
  return m.format(format)
}

export const momentDateStrToLocalFormat = (date_str, date_format, format="LLLL") => {
  if (date_str) {
      return moment(date_str, date_format).format(format)
  } else {
      return moment().format(format)
  }
}

export const momentTSToLocalFormat = (ts, format="LLLL") => {
  if (ts) {
      return moment(ts).format(format)
  } else {
      return moment().format(format)
  }
}

/**
 * convert money currency list
 */
export const convert_money_currency_list = {
  "USD": "$",
  "EUR": "€",
  "CAD": "CA$",
  "GBP": "£",
}
export const convertMoney = (amount, precision=2, currency='USD') => {
  if(!Object.keys(convert_money_currency_list).includes(currency)){
    currency='USD';
  }
  amount = Number(amount);
  return convert_money_currency_list[currency] + amount.toFixed(precision);
}

export function digitСonversion(data, style, currency, maximumFractionDigits=2){
  const lng = navigator.language || navigator.userLanguage;
  let number = Number(data);
  let settings = {
    style: "decimal",
    maximumFractionDigits: maximumFractionDigits
  };
  if(!currency){ currency = 'USD'; }

  function transformNumberToAbbreviated(number, decPlaces) {
    decPlaces = Math.pow(10,decPlaces);
    const abbrev = [ "k", "M", "b", "t" ];
      for (let i=abbrev.length-1; i>=0; i--) {
        const size = Math.pow(10,(i+1)*3);
        if (size <= number) {
          number = Math.round(number*decPlaces/size)/decPlaces;
          number += abbrev[i];
          break;
        }
      }
    return number;
  }

  if (style) {
    switch (style) {
      case "decimal":
        settings = {
          style: "decimal",
          maximumFractionDigits: maximumFractionDigits
        };
        break;
      case "percent":
        settings = {
          style: "percent",
          maximumFractionDigits: maximumFractionDigits
        };
        number = number / 100;
        break;
      case "currency":
        settings = {
          style: "currency",
          currency: currency,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        };
        break;
      case "rank":
        return transformNumberToAbbreviated(data, 1);

      default:
        settings = {
          style: "decimal",
          maximumFractionDigits: maximumFractionDigits
        };
    }
  }

  const formatter = new Intl.NumberFormat(lng, settings);
  return formatter.format(number);
}

export function openInNewTab(url) {
  let win = window.open(url);
  win.focus();
}

export function openInNewTabExtended(url, tabName, focus=false) {
  let win = window.open(url, tabName);
  if(focus){
    win.focus();
  }
}

export function momentDisplayAge(date){
  const date_from = moment(date).from();
  return date_from;
}

export function isObjectEmpty(obj){
  for(const i in obj){ return false; }
  return true;
}
