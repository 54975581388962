import React, { Component } from 'react';
import { connect } from "react-redux";
import {
  Card, CardBody, Row, Spinner, Col,
  Nav, NavItem, NavLink as NavLinkTab, TabContent, TabPane
} from 'reactstrap';
import { amazing, bad, great, ok, terrible, gold, silver, bronze } from '../../assets/images';
import DateIntervalDropdown from '../../shared/components/DateIntervalDropdown';
import './style.css';
import dashboardActions from "../../redux/dashboard/actions";
import moment from "moment";
import userActions from "../../redux/user/actions";
import Translate from "../../shared/components/Translate";
import ReactWordcloud from 'react-wordcloud';
import ReactApexChart from "react-apexcharts";

const { userUpdateSettings } = userActions;

const {
  dashboardTopStats,
  dashboardOperators,
  dashboardListOperators,
  dashboardOperatorsTopPerformers,
  dashboardOperatorsRatio,
  dashboardExportOperators,
  dashboardOperatorWordCloud,
} = dashboardActions;

const ratingMap = {
  1 : { emoji: terrible, text: "Terrible" },
  2 : { emoji: bad, text: "Bad" },
  3 : { emoji: ok, text: "OK" },
  4 : { emoji: great, text: "Great" },
  5 : { emoji: amazing, text: "Amazing" }
}

class Dashboard extends Component {
  state = {
    selectedInterval: {
      value: `${moment().startOf('month').format("YYYYMMDD")}-${moment().format("YYYYMMDD")}`,
      label: <Translate>This Month</Translate>
    },
    showIntervalDropdown: false,
    range: {
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      key: 'selection'
    },
    ratings_table_state: {
      page: 1,
      sizePerPage: 10,
      loading: false,
      sortOrder: 'desc',
      sortField: 'id',
    },
    user_id: null,
    hourlyFilterActive: false,
    hours_range: null,
    activeTabId: "1",
    ratingFilter: null
  }

  toggleIntervalDropdown = () => {
    this.setState({
      showIntervalDropdown: !this.state.showIntervalDropdown
    })
  }

  customRangesChange = (ranges) => {
    this.setState({
      range: ranges['selection'],
    })
  }

  selectInterval = (selected) => {
    this.setState({ selectedInterval: selected })
    this.searchData({
      interval: selected.value,
    })
    this.props.dashboardOperatorWordCloud({
      interval: selected.value,
    })
  }

  applyRange = () => {
    const { range } = this.state;

    const start = moment(range.startDate).format("YYYYMMDD");
    const end = moment(range.endDate).format("YYYYMMDD");
    this.selectInterval({value: `${start}-${end}`, label: <Translate>Custom</Translate>});
  }

  componentDidMount = () => {
    const today = moment().format("YYYYMMDD");
    const start_month = moment().startOf('month').format("YYYYMMDD");
    const interval = `${start_month}-${today}`;

    this.props.dashboardTopStats({interval: interval});
    this.props.dashboardOperatorsRatio({interval: interval})
    this.props.dashboardOperatorWordCloud({
      interval: interval,
    })
  }

  createOperatorDropdown = () => {
    const { operators_data } = this.props;
    var data = [
      {value: null, label: <Translate>All reps</Translate>}
    ]
    if(operators_data){
      operators_data.forEach(item => {
        data.push({
          value: item.user_id,
          label: item.first_name,
        })
      })
    }
    return data
  }

  searchData = (data) => {
    const {
      selectedInterval,
    } = this.state;

    this.props.dashboardTopStats({
      interval: data.interval ? data.interval : selectedInterval.value
    });

    this.props.dashboardOperatorsTopPerformers({
      interval: data.interval ? data.interval : selectedInterval.value,
      count: 1,
    })

    this.props.dashboardOperatorsRatio({
      interval: data.interval ? data.interval : selectedInterval.value,
    })
  }

  setRatingTableState = (state) => {
    var old_state = this.state.ratings_table_state;
    this.setState({ ratings_table_state: {...old_state, ...state}})
  }

  createTimeDropdown = () => {
    const today = moment().format("YYYYMMDD");
    const start_month = moment().startOf('month').format("YYYYMMDD");
    const start_year = moment().startOf('year').format("YYYYMMDD");
    const start_quarter = moment().startOf('quarter').format("YYYYMMDD");
    const end_quarter = moment().endOf('quarter').format("YYYYMMDD");

    const options = [
      {value: `${start_month}-${today}`, label: <Translate>This Month</Translate>},
      {value: `${start_quarter}-${end_quarter}`, label: <Translate>This Quarter</Translate>},
      {value: `${start_year}-${today}`, label: <Translate>This Year</Translate>},
    ]
    return options;
  }

  generateDataWordCloudList = () => {
    const { operators_data_word_cloud } = this.props;
    var data = {
      data: [],
      categories: [],
    }
    if(!operators_data_word_cloud){ return data; }

    var sorted = operators_data_word_cloud.sort((a,b) => b.value - a.value).slice(0, 20);
    data.data = sorted.map(item => item.value);
    data.categories = sorted.map(item => item.text);
    return data;
  }

  toggleFilter = (rating) => {
    const { activeFilter } = this.state;
    this.setState({ ratingFilter: (rating === activeFilter || rating === null) ? null : rating })
    this.searchData({ rating: (rating === activeFilter || rating === null) ? null : rating });
  }

  render() {
    const {
      dashboard_top_data,
      dashboard_top_data_loading,
      operators_data_top_performers,
      operators_data_ratio,
      operators_data_word_cloud,
    } = this.props;

    return (
      <div className="main-content view-dashboard">
        <div className="page-header">
          <h2 className="page-title"><Translate>Dashboard</Translate> <span className="title-info"><Translate>data as of</Translate>: {moment().format("MMMM Do, YYYY")} </span></h2>
          <div className="controls">
            <DateIntervalDropdown
              isOpen={this.state.showIntervalDropdown}
              toggle={this.toggleIntervalDropdown}
              selected={this.state.selectedInterval}
              options={this.createTimeDropdown()}
              onSelect={this.selectInterval}
              showCustom
              customRanges={[this.state.range]}
              customRangesChange={this.customRangesChange}
              applyRange={this.applyRange}
              rightDropdown
            />
            {/* <div className="hstack gap-1">
              <FormGroup check className="mb-0 me-1">
                <Input
                  type="checkbox"
                  id="hourlyFilter"
                  value={this.state.hourlyFilterActive}
                  onChange={() => this.setState({
                    hourlyFilterActive: !this.state.hourlyFilterActive,
                    hours_range: null
                  })}
                />
                <Label check for="hourlyFilter" className="position-relative">
                  <Translate>Set hours</Translate>
                  <BsInfoCircleFill className="icon-info" id="hours-popover" />
                </Label>
                <UncontrolledPopover
                  target="hours-popover"
                  placement="top"
                  trigger="hover"
                >
                  <PopoverBody>
                    <Translate
                      special_data="dashboard_hours_info"
                    >
                      Narrow your search by defining certain hours to search surveys from
                    </Translate>
                  </PopoverBody>
                </UncontrolledPopover>
              </FormGroup>
              <TimeRangePicker
                format="h a"
                hourPlaceholder="H"
                disableClock={true}
                disabled={!this.state.hourlyFilterActive}
                value={this.state.hours_range}
                onChange={(value) => this.setState({ hours_range: value })}
              />
              <Button
                size="sm"
                disabled={!this.state.hourlyFilterActive || this.props.operators_data_loading}
                onClick={() => this.searchData({})}
              ><Translate>Go</Translate></Button>
            </div> */}
          </div>
        </div>

        <div className="page-content">
          <Row>
            <Col lg={5}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center" style={{ minHeight: '4.8rem' }}>
                    <div className="metric-simple">
                      <h6 className="title"><Translate>Comment Ratio</Translate></h6>
                      <span className="value">{operators_data_ratio?.comments_ratio}%</span>
                    </div>
                    <div className="metric-simple">
                      <h6 className="title"><Translate>Rating Ratio</Translate></h6>
                      <span className="value">{operators_data_ratio?.rating_ratio}%</span>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <h3 className="page-section-title"><Translate>Leaderboard</Translate> <span className="title-info"><Translate>based on time filter</Translate></span></h3>
              <div className="top-ranks" style={{ marginBottom: '2.5rem' }}>
              {operators_data_top_performers &&
                operators_data_top_performers.map((item, idx) => {
                  if(idx === 0){
                    return (
                      <Card
                        className="card-top-operator first"
                        key={`card-top-operator-${idx}`}
                      >
                        <CardBody>
                          <span className="trophy"><img src={gold} alt="Gold" /></span>
                          <div className="profile-operator">
                            <span
                              className="avatar"
                              style={{ backgroundImage: `url(${item?.avatar})` }}
                            >
                            </span>
                            <div className="text">
                              <span className="name">{item?.first_name}</span>
                            </div>
                          </div>
                          <div className="achievement">
                            <div className="achievement-item">
                              <img src={amazing} alt="Amazing" />
                              <span className="percentage">{item?.stats["5_avg"]}%</span>
                              <span className="survey-number">({item?.stats['5'] ? item?.stats['5'] : 0})</span>
                            </div>
                            <div className="achievement-item">
                              <img src={great} alt="Great" />
                              <span className="percentage">{item?.stats["4_avg"]}%</span>
                              <span className="survey-number">({item?.stats['4'] ? item?.stats['4'] : 0})</span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    )
                  } else {
                    return (
                      <Card
                        className="card-top-operator"
                        key={`card-top-operator-${idx}`}
                      >
                        <CardBody>
                          <span className="trophy">
                            {idx < 3 &&
                              <img src={idx === 1 ? silver : bronze } alt={idx === 1 ? "Silver" : "Bronze"} />
                            }
                          </span>
                          <div className="profile-operator">
                            <span
                              className="avatar"
                              style={{ backgroundImage: `url(${item?.avatar})` }}
                            >
                            </span>
                            <div className="text">
                              <span className="name">{item?.first_name}</span>
                            </div>
                          </div>
                          <div className="achievement">
                            <div className="achievement-item">
                              <img src={amazing} alt="Amazing" />
                              <span className="percentage">{item?.stats["5_avg"]}%</span>
                              <span className="survey-number">({item?.stats['5'] ? item?.stats['5'] : 0})</span>
                            </div>
                            <div className="achievement-item">
                              <img src={great} alt="Great" />
                              <span className="percentage">{item?.stats["4_avg"]}%</span>
                              <span className="survey-number">({item?.stats['4'] ? item?.stats['4'] : 0})</span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    )
                  }
              })}
              </div>
            </Col>
            <Col lg={7}>
              <Card>
                <CardBody>
                  {dashboard_top_data_loading &&
                    <div className="loading-indicator">
                      <Spinner color="secondary" /> <span><Translate>Loading...</Translate></span>
                    </div>
                  }
                  <div className={`metric-rating-group`}>
                    {!dashboard_top_data_loading
                        && dashboard_top_data
                        && dashboard_top_data.map((item, idx) =>
                          <div
                            className={`metric-rating ${this.state.ratingFilter === item.rating ? 'active' : ''}`}
                            key={`metric-rating-list-${idx}`}
                          >
                            <img
                              src={ratingMap[item.rating].emoji}
                              alt={ratingMap[item.rating].text}
                              title={ratingMap[item.rating].text}
                            />
                            <span className="percentage">{item.percentage}%</span>
                            <span className="number">{item.count}</span>
                          </div>
                    )}
                  </div>
                </CardBody>
              </Card>

              <div className="hstack justify-content-between flex-wrap mb-2">
                <h3 className="page-section-title mb-0"><Translate>What customers are saying about you</Translate></h3>
                <Nav pills>
                  <NavItem>
                    <NavLinkTab
                      active={this.state.activeTabId === "1"}
                      onClick={() => this.setState({ activeTabId: "1" })}
                    >Word Cloud</NavLinkTab>
                  </NavItem>
                  <NavItem>
                    <NavLinkTab
                      active={this.state.activeTabId === "2"}
                      onClick={() => this.setState({ activeTabId: "2" })}
                    >Top 20   Words</NavLinkTab>
                  </NavItem>
                </Nav>
              </div>
              <Card>
                <TabContent activeTab={this.state.activeTabId}>
                  <TabPane tabId="1">
                    {this.state.activeTabId === "1" &&
                      <CardBody style={{ minHeight: '24.4rem' }}>
                        <div className="word-cloud my-4">
                          {operators_data_word_cloud.length > 0 ?
                          <ReactWordcloud
                            words={operators_data_word_cloud}
                            options={{
                              padding: 8,
                              rotations: 0,
                              rotationAngles: [0, 0],
                              deterministic: true,
                              fontFamily: "arial",
                              fontWeight: "bold",
                              fontSizes: [16, 60],
                              scale: "linear",
                              enableTooltip: false,
                            }}
                          />
                         : <p className="text-muted">No world cloud to be shown. Try to change timeframe of the data on top dropdown.</p>
                         }
                        </div>
                      </CardBody>
                    }
                  </TabPane>
                  <TabPane tabId="2">
                    {this.state.activeTabId === "2" &&
                    <CardBody>
                      <ReactApexChart
                        type="bar"
                        height={400}
                        series={[{
                          name: "Volume",
                          color: "#c0d5f1",
                          data: this.generateDataWordCloudList()['data'],
                        }]}
                        options={{
                          chart: {
                            type: "bar",
                            zoom: {
                              enabled: false
                            },
                            toolbar: {
                              show: false
                            },
                          },
                          grid: {
                            yaxis: {
                              lines: {
                                show: false
                               }
                             },
                          },
                          plotOptions: {
                            bar: {
                              horizontal: true,
                              borderRadius: 3,
                              dataLabels: {
                                position: 'top'
                              }
                            }
                          },
                          dataLabels: {
                            enabled: true,
                            offsetX: -6,
                            offsetY: 1,
                            style: {
                              fontSize: '10px',
                              colors: ['#2f80ed']
                            }
                          },
                          xaxis: {
                            categories: this.generateDataWordCloudList()['categories'],
                          },
                        }}
                      />
                    </CardBody>
                    }
                  </TabPane>
                </TabContent>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    dashboard_top_data: state.Dashboard.get("dashboard_top_data"),
    dashboard_top_data_loading: state.Dashboard.get("dashboard_top_data_loading"),
    operators_data: state.Dashboard.get("operators_data"),
    operators_data_loading: state.Dashboard.get("operators_data_loading"),
    operators: state.Dashboard.get("operators"),
    operators_loading: state.Dashboard.get("operators_loading"),
    operators_data_top_performers: state.Dashboard.get("operators_data_top_performers"),
    operators_data_top_performers_loading: state.Dashboard.get("operators_data_top_performers_loading"),
    operators_data_ratio: state.Dashboard.get("operators_data_ratio"),
    operators_data_ratio_loading: state.Dashboard.get("operators_data_ratio_loading"),
    userData: state.User.get("userData"),
    user_data_saving: state.User.get("user_data_saving"),
    operators_data_word_cloud: state.Dashboard.get("operators_data_word_cloud"),
  }),
  {
    dashboardTopStats,
    dashboardOperators,
    dashboardListOperators,
    dashboardOperatorsTopPerformers,
    dashboardOperatorsRatio,
    userUpdateSettings,
    dashboardExportOperators,
    dashboardOperatorWordCloud,
  }
)(Dashboard);
