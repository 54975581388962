import React, { Component } from 'react';
import { connect } from "react-redux";
import { NavLink } from 'react-router-dom';
import { Nav, NavItem } from 'reactstrap';
import { BsHouse, BsUiChecks, BsPersonCircle } from 'react-icons/bs';
import './style.css';
import userActions from "../../../redux/user/actions";
import Translate from "../Translate";

const { userUpdateSettings } = userActions;

class LeftNavigation extends Component {

  render() {
    //const { userData, user_settings_loading } = this.props;

    return (
      <div className="left-navigation">
        <Nav vertical>
          <NavItem>
            <NavLink exact to="/rating/dashboard" className="nav-link"><BsHouse className="menu-icon" /> <span><Translate>Dashboard</Translate></span></NavLink>
          </NavItem>
          <NavItem>
            <NavLink exact to="/rating/my_surveys" className="nav-link"><BsUiChecks className="menu-icon" /> <span><Translate>My Surveys</Translate></span></NavLink>
          </NavItem>
          <NavItem>
            <NavLink exact to="/rating/settings/my_account" className="nav-link"><BsPersonCircle className="menu-icon" /> <span><Translate>My Account</Translate></span></NavLink>
          </NavItem>
        </Nav>
      </div>
    )
  }
}

export default connect(
  state => ({
    //userData: state.User.get("userData"),
    //user_settings_loading: state.User.get("user_settings_loading"),
  }),
  {
    userUpdateSettings,
  }
)(LeftNavigation);
