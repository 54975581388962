export const appConfig = {
	environments: {
		"production": "production",
		"development": "development",
	},
	backendApiUrl: {
    "production": "https://backend.ratingfish.com/api/v1.0",
		"development": "http://127.0.0.1:5000/api/v1.0",
	},
	auth: {
		refreshTokenFrequency: 60000*15,
  },
  rootDomain: {
    "production": "app.ratingfish.com",
    "development": "localhost:3000",
  },

  messages_default_text: {
    US: {
        request_rating: {
            content_text: "Please leave a quick rating for {name}.\nHelp them understand how your experience was with this interaction.",
            content_choices_1: "Terrible",
            content_choices_2: "Bad",
            content_choices_3: "OK",
            content_choices_4: "Great",
            content_choices_5: "Amazing"
        },
        request_rating_comment: {
            content_text: "Comment on your support experience:",
            content_explain: "Your comment here..."
        },
        request_recommendation: {
            content_text: "How likely are you to recommend {name} to the seller community?",
            content_choices_5: "Not recommend",
            content_choices_9: "Maybe",
            content_choices_10: "Yes recommend"
        }
    },
    RS: {
        request_rating: {
            content_text: "Ocenite konverzaciju sa {name}.\nPomozite name da shvatimo kakvo je Vaše iskustvo sa podrškom.",
            content_choices_1: "Užasno",
            content_choices_2: "Loše",
            content_choices_3: "U redu",
            content_choices_4: "Odlično",
            content_choices_5: "Neverovatno"
        },
        request_rating_comment: {
            content_text: "Komentar na Vaše iskustvo sa podrškom:",
            content_explain: "Komentar..."
        },
        request_recommendation: {
            content_text: "Koliko je verovatno da ćete proporučiti {name}?",
            content_choices_5: "Ne preporučujem",
            content_choices_9: "Možda",
            content_choices_10: "Da preporučujem"
        }
    },
    FR: {
        request_rating: {
            content_text: "Veuillez laisser une note rapide pour {name}.\nAidez-les à comprendre votre expérience avec cette interaction.",
            content_choices_1: "Horrible",
            content_choices_2: "Mauvais",
            content_choices_3: "D'accord",
            content_choices_4: "Génial",
            content_choices_5: "Incroyable"
        },
        request_rating_comment: {
            content_text: "Commentez votre expérience d'assistance:",
            content_explain: "Votre commentaire ici..."
        },
        request_recommendation: {
            content_text: "Quelle est la probabilité que vous recommandiez {name} à la communauté des vendeurs?",
            content_choices_5: "Non recommandé",
            content_choices_9: "Peut-être",
            content_choices_10: "Oui recommander"
        }
    },
    DE: {
        request_rating: {
            content_text: "Bitte hinterlassen Sie eine kurze Bewertung für {name}.\nHelfen Sie ihnen zu verstehen, wie Sie diese Interaktion erlebt haben.",
            content_choices_1: "Schrecklich",
            content_choices_2: "Schlecht",
            content_choices_3: "OK",
            content_choices_4: "Großartig",
            content_choices_5: "Erstaunlich"
        },
        request_rating_comment: {
            content_text: "Kommentieren Sie Ihre Support-Erfahrung:",
            content_explain: "Dein Kommentar hier...."
        },
        request_recommendation: {
            content_text: "Wie wahrscheinlich ist es, dass Sie {name} der Verkäufer-Community weiterempfehlen?",
            content_choices_5: "Nicht zu empfehlen",
            content_choices_9: "Vielleicht",
            content_choices_10: "Ja, empfehlen"
        }
    },
    PT: {
        request_rating: {
            content_text: "Por favor, deixe uma avaliação rápida para {name}.\nAjude-os a entender como foi sua experiência com essa interação.",
            content_choices_1: "Terrível",
            content_choices_2: "Ruim",
            content_choices_3: "OK",
            content_choices_4: "Ótimo",
            content_choices_5: "Incrível"
        },
        request_rating_comment: {
            content_text: "Comente sobre sua experiência de suporte:",
            content_explain: "Seu comentário aqui..."
        },
        request_recommendation: {
            content_text: "Qual a probabilidade de você recomendar {name} à comunidade de vendedores?",
            content_choices_5: "Não recomendado",
            content_choices_9: "Talvez",
            content_choices_10: "Sim recomendo"
        }
    },
    ES: {
        request_rating: {
            content_text: "Deje una calificación rápida para {name}.\nAyúdelos a comprender cómo fue su experiencia con esta interacción.",
            content_choices_1: "Terrible",
            content_choices_2: "Malo",
            content_choices_3: "Aceptar",
            content_choices_4: "Genial",
            content_choices_5: "Increíble"
        },
        request_rating_comment: {
            content_text: "Comenta tu experiencia de soporte:",
            content_explain: "Tu comentario aquí..."
        },
        request_recommendation: {
            content_text: "Qué probabilidades hay de que recomiendes {name} a la comunidad de vendedores?",
            content_choices_5: "No recomendado",
            content_choices_9: "Tal vez",
            content_choices_10: "Sí, lo recomiendo"
        }
    },
    AE: {
        request_rating: {
            content_text: "الرجاء ترك تقييم سريع لـ {name}..\nاعدهم على فهم كيف كانت تجربتك مع هذا التفاعل.",
            content_choices_1: "رهيب",
            content_choices_2: "سيء",
            content_choices_3: "موافق",
            content_choices_4: "رائع",
            content_choices_5: "رائع"
        },
        request_rating_comment: {
            content_text: "تعليق على تجربة الدعم الخاصة بك:",
            content_explain: "تعليقك هنا..."
        },
        request_recommendation: {
            content_text: "ما مدى احتمالية تزكية {name} لمجتمع البائعين؟",
            content_choices_5: "غير موصى به",
            content_choices_9: "ربما",
            content_choices_10: "نعم أوصي"
        }
    },
    KR: {
        request_rating: {
            content_text: "{name}에 대한 빠른 평가를 남겨주세요.\n이 상호 작용에 대한 귀하의 경험이 어땠는지 이해하도록 도와주세요",
            content_choices_1: "끔찍함",
            content_choices_2: "나쁨",
            content_choices_3: "확인",
            content_choices_4: "훌륭합니다",
            content_choices_5: "놀라움"
        },
        request_rating_comment: {
            content_text: "지원 경험에 대한 의견::",
            content_explain: "여기에 귀하의 의견이..."
        },
        request_recommendation: {
            content_text: "판매자 커뮤니티에 {name}을(를) 추천할 가능성이 얼마나 됩니까?",
            content_choices_5: "권장하지 않음",
            content_choices_9: "아마도",
            content_choices_10: "네 추천합니다"
        }
    },
  },
}
