import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { FormGroup, Input, Button, Form, Card, CardBody } from 'reactstrap';
import authActions from "../../redux/auth/actions";
import { withRouter } from "react-router";
import { logo } from '../../assets/images';
import queryString from 'query-string';

const { userLogin } = authActions;

class Login extends Component {
  state = {
    un: "",
    pw: "",
  }

  updateValue = (key, data) => {
    this.setState({ [key]: data });
  }

  login = () => {
    const { location } = this.props;
    const { un, pw } = this.state;
    this.setState({ pw: ""})
    let params = queryString.parse(location?.search);
    const data = {
      username: un,
      password: pw,
      website_id: params?.website_id,
      token: params?.token,
    }
    this.props.userLogin(data);
  }

  onFormSubmit = (e) => {
    e.preventDefault();
    this.login();
  }

  render() {
    return (
      <main>
        <div className="centered-content-wrapper">
          <div>
            <div className="text-center mb-4">
              <img src={logo} alt="Logo" />
              <h1 className="h5 mt-2">Dashboard</h1>
            </div>
            <Card>
              <CardBody>
                <div className="auth-box">
                  <Form onSubmit={this.onFormSubmit}>
                    <FormGroup>
                      <Input
                        placeholder="Username"
                        onChange={e => this.updateValue("un", e.target.value)}
                        value={this.state.un}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type="password"
                        placeholder="Password"
                        onChange={e => this.updateValue("pw", e.target.value)}
                        value={this.state.pw}
                      />
                    </FormGroup>
                    <div className="d-flex justify-content-between align-items-center">
                      <Button
                        type="submit"
                        color="primary"
                        disabled={this.props.login_working}
                      >Sign in</Button>
                      <Link to="/forgot_password">Forgot password?</Link>
                    </div>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </main>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      login_failed: state.Auth.get("login_failed"),
      login_failed_message: state.Auth.get("login_failed_message"),
      login_working: state.Auth.get("login_working"),
    }),
    {
      userLogin,
    }
  )(Login)
);
