import { Map } from "immutable";

const initState = new Map({
  users_list: [],
  users_list_loading: false,
  app_settings_loading: false,
  website_count: null,
});

export default function adminReducer(state = initState, action) {
	switch (action.type) {
		case "ADMIN_WEBSITE_COUNT":
			return state
				.set("website_count", null)
		case "ADMIN_WEBSITE_COUNT_SUCCESS":
			return state
				.set("website_count", action.data.data)
		case "ADMIN_WEBSITE_COUNT_FAILED":
			return state
				.set("website_count", null)

		case "ADMIN_UPDATE_APP_SETTINGS":
			return state
				.set("app_settings_loading", true)
		case "ADMIN_UPDATE_APP_SETTINGS_SUCCESS":
			return state
				.set("app_settings_loading", false)
		case "ADMIN_UPDATE_APP_SETTINGS_FAILED":
			return state
				.set("app_settings_loading", false)

		case "ADMIN_GET_USERS":
			return state
				.set("users_list", [])
				.set("users_list_loading", true)
		case "ADMIN_GET_USERS_SUCCESS":
			return state
				.set("users_list", action.data.users)
				.set("users_list_loading", false)
		case "ADMIN_GET_USERS_FAILED":
			return state
				.set("users_list", [])
				.set("users_list_loading", false)

		default:
			return state;
  }
}
