import axios from "axios";
import defaults from "superagent-defaults";
import { getTokenLocal } from "../helpers/auth/utils";
import { createAxiosResponseInterceptorForClient } from "../helpers/auth/token_refresh";
import { appConfig } from "./app";

const apiEndpoints = {
  auth: {
    login: "/auth/login",
    refresh_token: "/auth/refresh",
    passwordreset: "/auth/passwordreset",
    register: "/auth/register",
    admin_login: "/auth/admin_login",
    register_password_reset: "/auth/register/password/reset",
    employee_login: "/auth/employee/login",
  },
  user: {
    user: "/user",
    password_reset: "/user/password/reset",
    slack_auth: "/user/slack/auth",
    slack_channel: "/user/slack/channel",
    slack_user: "/user/slack/user",
    settings: "/user/settings",
    segmentation: "/user/segmentation",
    employee: "/user/employee",
    employees: "/user/employees",
    alert_dismiss: "/user/alert/dismiss",
  },
  dashboard: {
    stats_top: "/dashboard/stats/top",
    stats_operators: "/dashboard/stats/operators",
    stats_operators_export: "/dashboard/stats/operators/export",
    operators: "/dashboard/operators",
    operators_top_performers: "/dashboard/operators/top_performers",
    operators_ratio: "/dashboard/operators/ratio",
    operators_exclude: "/dashboard/operators/exclude",
    operator_review: "/dashboard/operator/review",
  },
  operator: {
    word_cloud: "/operator/word_cloud",
    operator: "/operator",
  },
  admin: {
    users: "/admin/users",
    app_settings: "/admin/app/settings",
    website_count: "/admin/website/count",
  },
  website: {
    languages: "/website/languages",
    website: "/website"
  }
};

const env =
  process.env.REACT_APP_NODE_ENV || appConfig.environments["production"];

const API_ROOT = appConfig.backendApiUrl[env];

const client = axios.create({ baseURL: API_ROOT });

const superagent = defaults();

const request = {
  init: () =>
    superagent.set(
      "Authorization",
      `Bearer ${getTokenLocal().toJS().access_token}`
    ),
  delete: (url) => request.init().delete(url),
  get: (url) => request.init().get(url),
  post: (url) => request.init().post(url),
  put: (url) => request.init().put(url),
};

client.defaults.headers.common["Authorization"] = `Bearer ${
  getTokenLocal().toJS().access_token
}`;

const interceptor = createAxiosResponseInterceptorForClient(client);

interceptor();

export { request, API_ROOT, client, apiEndpoints };
