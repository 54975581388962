import {
  all,
  fork,
  select,
  put,
  takeLatest,
  call,
} from "redux-saga/effects";
//import { push } from "react-router-redux";
import { apiCalls } from "../../helpers/api/utils";
import { apiEndpoints } from "../../config/api";
import actions from "./actions";
//import { sdMessage } from "../../helpers/utils";
import { commonSaga, default_config } from "../../helpers/redux/utils";
import userActions from "../user/actions";
import { selectorUserData } from "../user/selector";

export function* adminGetUsers() {
  yield commonSaga(
    actions.ADMIN_GET_USERS,
    apiCalls.get,
    apiEndpoints.admin.users,
    {...default_config, ...{success_show: false}}
  );
}

export function* adminUpdateAppSettings() {
	yield takeLatest(actions.ADMIN_UPDATE_APP_SETTINGS, function*(payload) {
		try {
			const res = yield call(apiCalls.put, apiEndpoints.admin.app_settings, payload.data);
			if (res.data.data.error) {
				yield put(actions.adminUpdateAppSettingsFailed());
			} else {
				yield put(actions.adminUpdateAppSettingsSuccess());
        var userData = yield select(selectorUserData);
        userData.app_settings = {...res.data.data}
        yield put(userActions.userSetData(userData));
			}
		} catch (error) {
				yield put(actions.adminUpdateAppSettingsFailed());
		}
	})
}

export function* adminWebsiteCount() {
  yield commonSaga(
    actions.ADMIN_WEBSITE_COUNT,
    apiCalls.get,
    apiEndpoints.admin.website_count,
    {...default_config, ...{success_show: false}}
  );
}

export default function* adminSaga() {
	yield all([
    fork(adminGetUsers),
    fork(adminUpdateAppSettings),
    fork(adminWebsiteCount),
	]);
}
