import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Router,
  Route,
  Switch,
  Redirect,
  withRouter
} from 'react-router-dom';
import { appConfig } from './config/app';
import { Container } from 'reactstrap';
import LeftNavigation from './shared/components/LeftNavigation';
import TopBar from './shared/components/TopBar';
import authActions from './redux/auth/actions';

// Views
import Dashboard from './views/Dashboard';
import Login from './views/Login';
import MyAccount from './views/Settings/MyAccount';
import Surveys from "./views/Surveys";

const { userRefreshToken, userLogout } = authActions;

const RouteUser = ({ component: Component, isLoggedIn, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        isLoggedIn ? (
          <TemplateUser>
            <Component {...matchProps} />
          </TemplateUser>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: matchProps.location }
            }}
          />
        )
      )}
    />
  );
}

class TemplateUser extends Component {
  render() {
    const {
      children
    } = this.props;

    return (
      <div className="app">
        <div className="content">
          <TopBar />
          <Container fluid className="content-bottom">
            <LeftNavigation />
            <div className="right-content">
              {children}
            </div>
          </Container>
        </div>
      </div>
    )
  }
}

TemplateUser = withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
    }),
    {
    }
  )(TemplateUser)
);

class AppPrivateRoutes extends Component {

  componentDidMount(){
    //this.props.userRefreshToken();
    setInterval(() => {
      this.props.userRefreshToken();},
      appConfig.auth.refreshTokenFrequency
    );
  }

  componentDidUpdate(prevProps) {
  }

  render(){
    return (this.props.children);
  }
}

AppPrivateRoutes = withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
    }),
    {
      userRefreshToken,
    }
  )(AppPrivateRoutes)
);

class AppRoutes extends Component {
  render() {
    const {
      history,
      isLoggedIn
    } = this.props;

    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route path="/rating/">
            <AppPrivateRoutes location={this.props.location}>
              <RouteUser
                exact path="/rating/dashboard"
                component={Dashboard}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/rating/my_surveys"
                component={Surveys}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/rating/settings/my_account"
                component={MyAccount}
                isLoggedIn={isLoggedIn}
              />
            </AppPrivateRoutes>
          </Route>
          <Redirect from="/" to="/login" />
        </Switch>
      </Router>
    )
  }
}

export default connect(
  state => ({
    isLoggedIn: state.Auth.get("access_token") !== null,
  }),
  {
    userRefreshToken,
    userLogout,
  }
)(AppRoutes);
