const dashboardActions = {
  DASHBOARD_TOP_STATS: 'DASHBOARD_TOP_STATS',
  DASHBOARD_LIST_OPERATORS: 'DASHBOARD_LIST_OPERATORS',
  DASHBOARD_OPERATORS: 'DASHBOARD_OPERATORS',
  DASHBOARD_OPERATORS_TOP_PERFORMERS: 'DASHBOARD_OPERATORS_TOP_PERFORMERS',
  DASHBOARD_OPERATORS_RATIO: 'DASHBOARD_OPERATORS_RATIO',
  DASHBOARD_OPERATORS_EXCLUDE: 'DASHBOARD_OPERATORS_EXCLUDE',
  DASHBOARD_OPERATORS_EXCLUDE_SUCCESS: 'DASHBOARD_OPERATORS_EXCLUDE_SUCCESS',
  DASHBOARD_OPERATORS_EXCLUDE_FAILED: 'DASHBOARD_OPERATORS_EXCLUDE_FAILED',
  DASHBOARD_OPERATOR_REVIEW: 'DASHBOARD_OPERATOR_REVIEW',
  DASHBOARD_OPERATOR_WORD_CLOUD: 'DASHBOARD_OPERATOR_WORD_CLOUD',
  DASHBOARD_OPERATOR_REVIEW_UPDATE: 'DASHBOARD_OPERATOR_REVIEW_UPDATE',
  DASHBOARD_EXPORT_OPERATORS: 'DASHBOARD_EXPORT_OPERATORS',

  dashboardExportOperators: (data) => ({
    type: dashboardActions.DASHBOARD_EXPORT_OPERATORS,
    data
  }),

  dashboardOperatorReviewUpdate: (data) => ({
    type: dashboardActions.DASHBOARD_OPERATOR_REVIEW_UPDATE,
    data
  }),

  dashboardOperatorWordCloud: (data) => ({
    type: dashboardActions.DASHBOARD_OPERATOR_WORD_CLOUD,
    data
  }),

  dashboardOperatorReview: (data) => ({
    type: dashboardActions.DASHBOARD_OPERATOR_REVIEW,
    data
  }),

  dashboardOperatorsExclude: (data) => ({
    type: dashboardActions.DASHBOARD_OPERATORS_EXCLUDE,
    data
  }),
  dashboardOperatorsExcludeSuccess: (data) => ({
    type: dashboardActions.DASHBOARD_OPERATORS_EXCLUDE_SUCCESS,
    data
  }),
  dashboardOperatorsExcludeError: (data) => ({
    type: dashboardActions.DASHBOARD_OPERATORS_EXCLUDE_FAILED,
    data
  }),

  dashboardOperatorsRatio: (data) => ({
    type: dashboardActions.DASHBOARD_OPERATORS_RATIO,
    data
  }),

  dashboardOperatorsTopPerformers: (data) => ({
    type: dashboardActions.DASHBOARD_OPERATORS_TOP_PERFORMERS,
    data
  }),

  dashboardOperators: (data) => ({
    type: dashboardActions.DASHBOARD_OPERATORS,
    data
  }),

  dashboardListOperators: (data) => ({
    type: dashboardActions.DASHBOARD_LIST_OPERATORS,
    data
  }),

  dashboardTopStats: (data) => ({
    type: dashboardActions.DASHBOARD_TOP_STATS,
    data
  }),
}

export default dashboardActions;
