import {
  all,
  takeLatest,
  put,
  fork,
  call,
  select,
} from "redux-saga/effects";
//import { push } from "react-router-redux";
import { apiCalls } from "../../helpers/api/utils";
import { apiEndpoints } from "../../config/api";
import actions from "./actions";
//import { sdMessage } from "../../helpers/utils";
import { commonSaga, default_config } from "../../helpers/redux/utils";
import userActions from "../user/actions";
import { selectorUserData } from "../user/selector";

import Papa from 'papaparse'
import fileDownload from 'js-file-download'

function exportOperators(res){
  const valid_headers = {
    "operator_name": "Agent Name",
    "created_at": "Date/Time",
    "customer_name": "Customer Name",
    "customer_email": "Customer Email",
    "rating": "Rating",
    "reccomendation_rating": "NPS Overall",
    "segmentation": "Segments",
    "comment": "Comment Text",
    "conversation_url": "Conversation",
  }

  var export_data = [];
  res.data.data.forEach(item => {
    var data = {}
    Object.keys(valid_headers).forEach(header => {
      data[valid_headers[header]] = item[header];
    })
    export_data.push(data);
  })

  const csv = Papa.unparse(JSON.stringify(export_data) , {
    delimiter: "\t",
    header: true,
    skipEmptyLines: false,
  });
  fileDownload(csv, 'rating-fish-dashboard-data-export.tsv');
}

export function* dashboardTopStats() {
  yield commonSaga(
    actions.DASHBOARD_TOP_STATS,
    apiCalls.get,
    apiEndpoints.dashboard.stats_top,
    {...default_config, ...{success_show: false}}
  );
}

export function* dashboardListOperators() {
  yield commonSaga(
    actions.DASHBOARD_LIST_OPERATORS,
    apiCalls.get,
    apiEndpoints.dashboard.stats_operators,
    {...default_config, ...{success_show: false}}
  );
}

export function* dashboardOperators() {
  yield commonSaga(
    actions.DASHBOARD_OPERATORS,
    apiCalls.get,
    apiEndpoints.dashboard.operators,
    {...default_config, ...{success_show: false}}
  );
}

export function* dashboardOperatorsTopPerformers() {
  yield commonSaga(
    actions.DASHBOARD_OPERATORS_TOP_PERFORMERS,
    apiCalls.get,
    apiEndpoints.dashboard.operators_top_performers,
    {...default_config, ...{success_show: false}}
  );
}

export function* dashboardOperatorsRatio() {
  yield commonSaga(
    actions.DASHBOARD_OPERATORS_RATIO,
    apiCalls.get,
    apiEndpoints.dashboard.operators_ratio,
    {...default_config, ...{success_show: false}}
  );
}

export function* dashboardOperatorsExclude() {
	yield takeLatest(actions.DASHBOARD_OPERATORS_EXCLUDE, function*(payload) {
		try {
			const res = yield call(apiCalls.put, apiEndpoints.dashboard.operators_exclude, payload.data);
			if (res.data.error) {
				yield put(actions.dashboardOperatorsExcludeError());
			} else {
				yield put(actions.dashboardOperatorsExcludeSuccess(res.data));
        var userData = yield select(selectorUserData);
        if(userData.website.dashboard_exclude_users_list.includes(res.data.exclude_id)){
          userData.website.dashboard_exclude_users_list = userData.website.dashboard_exclude_users_list.filter(item => item !==res.data.exclude_id);
        } else {
          userData.website.dashboard_exclude_users_list.push(res.data.exclude_id);
        }
        userData.website.dashboard_exclude_users = userData.website.dashboard_exclude_users_list.join(',');
        yield put(userActions.userSetData(userData));
			}
		} catch (error) {
				yield put(actions.dashboardOperatorsExcludeError());
		}
	})
}

export function* dashboardOperatorReview() {
  yield commonSaga(
    actions.DASHBOARD_OPERATOR_REVIEW,
    apiCalls.get,
    apiEndpoints.dashboard.operator_review,
    {...default_config, ...{success_show: false}}
  );
}

export function* dashboardOperatorWordCloud() {
  yield commonSaga(
    actions.DASHBOARD_OPERATOR_WORD_CLOUD,
    apiCalls.get,
    apiEndpoints.operator.word_cloud,
    {...default_config, ...{success_show: false}}
  );
}

export function* dashboardOperatorReviewUpdate() {
  yield commonSaga(
    actions.DASHBOARD_OPERATOR_REVIEW_UPDATE,
    apiCalls.put,
    apiEndpoints.operator.operator,
    {...default_config, ...{success_show: true}}
  );
}

export function* dashboardExportOperators() {
  yield commonSaga(
    actions.DASHBOARD_EXPORT_OPERATORS,
    apiCalls.get,
    apiEndpoints.dashboard.stats_operators_export,
    {
      ...default_config,
      ...{
        success_show: false
      }
    },
    [],
    [
      {
        function: exportOperators,
        params: []
      }
    ]
  );
}

export default function* dashboardSaga() {
	yield all([
    fork(dashboardTopStats),
    fork(dashboardListOperators),
    fork(dashboardOperators),
    fork(dashboardOperatorsTopPerformers),
    fork(dashboardOperatorsRatio),
    fork(dashboardOperatorsExclude),
    fork(dashboardOperatorReview),
    fork(dashboardOperatorWordCloud),
    fork(dashboardOperatorReviewUpdate),
    fork(dashboardExportOperators),
	]);
}
