const userActions = {
	USER_GET_DATA: 'USER_GET_DATA',
	USER_GET_DATA_SUCCESS: 'USER_GET_DATA_SUCCESS',
	USER_GET_DATA_ERROR: 'USER_GET_DATA_ERROR',

	USER_SET_DATA: 'USER_SET_DATA',
	USER_SET_DATA_SUCCESS: 'USER_SET_DATA_SUCCESS',
  USER_SET_DATA_ERROR: 'USER_SET_DATA_ERROR',

	USER_SAVE_DATA: 'USER_SAVE_DATA',
	USER_SAVE_DATA_SUCCESS: 'USER_SAVE_DATA_SUCCESS',
	USER_SAVE_DATA_ERROR: 'USER_SAVE_DATA_ERROR',

	USER_PASSWORD_RESET: 'USER_PASSWORD_RESET',
	USER_PASSWORD_RESET_SUCCESS: 'USER_PASSWORD_RESET_SUCCESS',
	USER_PASSWORD_RESET_ERROR: 'USER_PASSWORD_RESET_ERROR',

  USER_SEARCH: 'USER_SEARCH',
  USER_SEARCH_SINGLE: 'USER_SEARCH_SINGLE',

  USER_SLACK_AUTH_DANCE: "USER_SLACK_AUTH_DANCE",
  USER_SLACK_AUTH_START: "USER_SLACK_AUTH_START",
  USER_SLACK_GET_CHANNELS: "USER_SLACK_GET_CHANNELS",
  USER_SLACK_UPDATE_CHANNEL: "USER_SLACK_UPDATE_CHANNEL",
  USER_SLACK_AUTH_DELETE: "USER_SLACK_AUTH_DELETE",
  USER_SLACK_GET_USERS: "USER_SLACK_GET_USERS",
  USER_SLACK_UPDATE_USER: "USER_SLACK_UPDATE_USER",
  USER_WEBSITE_SEGMENTATION_UPDATE: "USER_WEBSITE_SEGMENTATION_UPDATE",

  USER_UPDATE_SETTINGS: "USER_UPDATE_SETTINGS",
  USER_WEBSITE_UPDATE: "USER_WEBSITE_UPDATE",

  USER_EMPLOYEE_ADD: "USER_EMPLOYEE_ADD",
  USER_EMPLOYEE_UPDATE: "USER_EMPLOYEE_UPDATE",
  USER_EMPLOYEE_LOAD: "USER_EMPLOYEE_LOAD",
  USER_EMPLOYEES_LOAD: "USER_EMPLOYEES_LOAD",
  USER_WEBSITE_SEGMENTATION_DELETE: "USER_WEBSITE_SEGMENTATION_DELETE",
  USER_ALERT_DISMISS: "USER_ALERT_DISMISS",

  userAlertDismiss: (data) => ({
    type: userActions.USER_ALERT_DISMISS,
    data
  }),

  userWebsiteDeleteSegmentation: (data) => ({
    type: userActions.USER_WEBSITE_SEGMENTATION_DELETE,
    data
  }),

  userEmployeesLoad: (data) => ({
    type: userActions.USER_EMPLOYEES_LOAD,
    data
  }),

  userEmployeeLoad: (data) => ({
    type: userActions.USER_EMPLOYEE_LOAD,
    data
  }),

  userEmployeeUpdate: (data) => ({
    type: userActions.USER_EMPLOYEE_UPDATE,
    data
  }),

  userEmployeeAdd: (data) => ({
    type: userActions.USER_EMPLOYEE_ADD,
    data
  }),

  userWebsiteUpdate: (data) => ({
    type: userActions.USER_WEBSITE_UPDATE,
    data
  }),

  userWebsiteUpdateSegmentation: (data) => ({
    type: userActions.USER_WEBSITE_SEGMENTATION_UPDATE,
    data
  }),

  userSlackUpdateUser: (data) => ({
    type: userActions.USER_SLACK_UPDATE_USER,
    data
  }),

  userSlackGetUsers: (data) => ({
    type: userActions.USER_SLACK_GET_USERS,
    data
  }),

  userSlackAuthDelete: (data) => ({
    type: userActions.USER_SLACK_AUTH_DELETE,
    data
  }),

  userSlackUpdateChannel: (data) => ({
    type: userActions.USER_SLACK_UPDATE_CHANNEL,
    data
  }),

  userSlackGetChannels: (data) => ({
    type: userActions.USER_SLACK_GET_CHANNELS,
    data
  }),

  userUpdateSettings: (data) => ({
    type: userActions.USER_UPDATE_SETTINGS,
    data
  }),

  userSlackAuthStart: (data) => ({
    type: userActions.USER_SLACK_AUTH_START,
    data
  }),

  userSlackAuthDance: (data) => ({
    type: userActions.USER_SLACK_AUTH_DANCE,
    data
  }),

  userSearchSingle: (data) => ({
    type: userActions.USER_SEARCH_SINGLE,
    data
  }),

  userSearch: (data) => ({
    type: userActions.USER_SEARCH,
    data
  }),

	userPasswordReset: (data) => ({
		type: userActions.USER_PASSWORD_RESET,
    data
	}),
	userPasswordResetSuccess: data => ({
		type: userActions.USER_PASSWORD_RESET_SUCCESS,
		data
	}),
	userPasswordResetError: (data) => ({
		type: userActions.USER_PASSWORD_RESET_ERROR,
    data
	}),

	userSaveData: (data) => ({
		type: userActions.USER_SAVE_DATA,
    data
	}),
	userSaveDataSuccess: data => ({
		type: userActions.USER_SAVE_DATA_SUCCESS,
		data
	}),
	userSaveDataError: (data) => ({
		type: userActions.USER_SAVE_DATA_ERROR,
    data
	}),

	userSetData: (data) => ({
	  type: userActions.USER_SET_DATA,
	  data
	}),
	userSetDataSuccess: (data) => ({
	  type: userActions.USER_SET_DATA_SUCCESS,
	  data
	}),
	userSetDataError: () => ({
	  type: userActions.USER_SET_DATA_ERROR,
	}),

	userGetData: () => ({
		type: userActions.USER_GET_DATA,
	}),
	userGetDataSuccess: data => ({
		type: userActions.USER_GET_DATA_SUCCESS,
		data
	}),
	userGetDataError: () => ({
		type: userActions.USER_GET_DATA_ERROR,
	}),

}

export default userActions;
