const adminActions = {
  ADMIN_GET_USERS: 'ADMIN_GET_USERS',
  ADMIN_UPDATE_APP_SETTINGS: 'ADMIN_UPDATE_APP_SETTINGS',
  ADMIN_UPDATE_APP_SETTINGS_SUCCESS: 'ADMIN_UPDATE_APP_SETTINGS_SUCCESS',
  ADMIN_UPDATE_APP_SETTINGS_FAILED: 'ADMIN_UPDATE_APP_SETTINGS_FAILED',
  ADMIN_WEBSITE_COUNT: 'ADMIN_WEBSITE_COUNT',

  adminWebsiteCount: (data) => ({
    type: adminActions.ADMIN_WEBSITE_COUNT,
    data
  }),
  adminUpdateAppSettings: (data) => ({
    type: adminActions.ADMIN_UPDATE_APP_SETTINGS,
    data
  }),
  adminUpdateAppSettingsSuccess: (data) => ({
    type: adminActions.ADMIN_UPDATE_APP_SETTINGS_SUCCESS,
    data
  }),
  adminUpdateAppSettingsFailed: (data) => ({
    type: adminActions.ADMIN_UPDATE_APP_SETTINGS_FAILED,
    data
  }),

  adminGetUsers: (data) => ({
    type: adminActions.ADMIN_GET_USERS,
    data
  }),
}

export default adminActions;
