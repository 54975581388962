import { Map } from "immutable";
import actions from "./actions";

const initState = new Map({
  dashboard_top_data: [],
  dashboard_top_data_loading: false,
  operators: {},
  operators_loading: false,
  operators_data: [],
  operators_data_loading: false,
  operators_data_top_performers: [],
  operators_data_top_performers_loading: false,
  operators_data_ratio: {},
  operators_data_ratio_loading: false,
  operators_data_review: {},
  operators_data_review_loading: false,
  operators_data_word_cloud: [],
});

export default function dashboardReducer(state = initState, action) {
	switch (action.type) {

		case "DASHBOARD_EXPORT_OPERATORS":
			return state
				.set("operators_loading", true)
		case "DASHBOARD_EXPORT_OPERATORS_SUCCESS":
			return state
				.set("operators_loading", false)
		case "DASHBOARD_EXPORT_OPERATORS_FAILED":
			return state
				.set("operators_loading", false)

		case "DASHBOARD_OPERATOR_REVIEW_UPDATE":
			return state
				.set("operators_data_review_loading", true)
		case "DASHBOARD_OPERATOR_REVIEW_UPDATE_SUCCESS":
      var update_operators_data_review = state.get("operators_data_review");
      update_operators_data_review.operator = {
        ...update_operators_data_review.operator,
        ...action.data.data,
      }
      var update_operators_data_top_performers = state.get("operators_data_top_performers");
      update_operators_data_top_performers = update_operators_data_top_performers.map(item => {
        if(item.id === action.data.data.id){
          item = {
            ...item,
            ...action.data.data,
          }
        }
        return item;
      })

			return state
        .set("operators_data_top_performers", [...update_operators_data_top_performers])
        .set("operators_data_review", {...update_operators_data_review})
				.set("operators_data_review_loading", false)
		case "DASHBOARD_OPERATOR_REVIEW_UPDATE_FAILED":
			return state
				.set("operators_data_review_loading", false)

		case "DASHBOARD_OPERATOR_WORD_CLOUD":
			return state
				.set("operators_data_word_cloud", {})
				.set("operators_data_review_loading", true)
		case "DASHBOARD_OPERATOR_WORD_CLOUD_SUCCESS":
			return state
				.set("operators_data_word_cloud", [...action.data])
				.set("operators_data_review_loading", false)
		case "DASHBOARD_OPERATOR_WORD_CLOUD_FAILED":
			return state
				.set("operators_data_word_cloud", {})
				.set("operators_data_review_loading", false)

		case "DASHBOARD_OPERATOR_REVIEW":
			return state
				.set("operators_data_review", {})
				.set("operators_data_review_loading", true)
		case "DASHBOARD_OPERATOR_REVIEW_SUCCESS":
			return state
				.set("operators_data_review", {...action.data.data})
				.set("operators_data_review_loading", false)
		case "DASHBOARD_OPERATOR_REVIEW_FAILED":
			return state
				.set("operators_data_review", {})
				.set("operators_data_review_loading", false)

		case actions.DASHBOARD_OPERATORS_EXCLUDE:
			return state
				.set("operators_data_top_performers_loading", true)
    case actions.DASHBOARD_OPERATORS_EXCLUDE_SUCCESS:
      var top_performers_exclude = state.get("operators_data_top_performers");
      top_performers_exclude = top_performers_exclude.filter(item => item.user_id !== action.data.exclude_id);
			return state
				.set("operators_data_top_performers", [...top_performers_exclude])
				.set("operators_data_top_performers_loading", false)
		case actions.DASHBOARD_OPERATORS_EXCLUDE_FAILED:
			return state
				.set("operators_data_top_performers_loading", false)

		case "DASHBOARD_OPERATORS_RATIO":
			return state
				.set("operators_data_ratio", {})
				.set("operators_data_ratio_loading", true)
		case "DASHBOARD_OPERATORS_RATIO_SUCCESS":
			return state
				.set("operators_data_ratio", {...action.data.data})
				.set("operators_data_ratio_loading", false)
		case "DASHBOARD_OPERATORS_RATIO_FAILED":
			return state
				.set("operators_data_ratio", {})
				.set("operators_data_ratio_loading", false)

		case "DASHBOARD_OPERATORS_TOP_PERFORMERS":
			return state
				.set("operators_data_top_performers", [])
				.set("operators_data_top_performers_loading", true)
		case "DASHBOARD_OPERATORS_TOP_PERFORMERS_SUCCESS":
			return state
				.set("operators_data_top_performers", [...action.data.data])
				.set("operators_data_top_performers_loading", false)
		case "DASHBOARD_OPERATORS_TOP_PERFORMERS_FAILED":
			return state
				.set("operators_data_top_performers", [])
				.set("operators_data_top_performers_loading", false)

		case "DASHBOARD_OPERATORS":
			return state
				.set("operators_data", [])
				.set("operators_data_loading", true)
		case "DASHBOARD_OPERATORS_SUCCESS":
			return state
				.set("operators_data", [...action.data.data])
				.set("operators_data_loading", false)
		case "DASHBOARD_OPERATORS_FAILED":
			return state
				.set("operators_data", [])
				.set("operators_data_loading", false)

		case "DASHBOARD_LIST_OPERATORS":
			return state
				.set("operators", {})
				.set("operators_loading", true)
		case "DASHBOARD_LIST_OPERATORS_SUCCESS":
			return state
				.set("operators", {...action.data.data})
				.set("operators_loading", false)
		case "DASHBOARD_LIST_OPERATORS_FAILED":
			return state
				.set("operators", {})
				.set("operators_loading", false)

		case "DASHBOARD_TOP_STATS":
			return state
				.set("dashboard_top_data", [])
				.set("dashboard_top_data_loading", true)
		case "DASHBOARD_TOP_STATS_SUCCESS":
			return state
				.set("dashboard_top_data", [...action.data.data])
				.set("dashboard_top_data_loading", false)
		case "DASHBOARD_TOP_STATS_FAILED":
			return state
				.set("dashboard_top_data", [])
				.set("dashboard_top_data_loading", false)

		default:
			return state;
  }
}
