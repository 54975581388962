import React, { Component } from 'react';
import { connect } from "react-redux";
import { NavLink } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavbarBrand, NavItem, UncontrolledDropdown } from 'reactstrap';
import { logo } from '../../../assets/images';
import {
  //BsArrowUpCircleFill,
  BsBoxArrowRight,
  BsPersonCircle,
  //BsReceipt,
} from "react-icons/bs";
import './style.css';
import 'react-toastify/dist/ReactToastify.css';
import Translate from "../../../shared/components/Translate";

import authActions from "../../../redux/auth/actions";
import userActions from "../../../redux/user/actions";

const { userGetData } = userActions;
const { userLogout } = authActions;

class TopBar extends Component {
  state = {
    user_data_loaded: false,
  }

  componentDidMount = () => {
    if(!this.props.userData?.id_user){
      this.props.userGetData();
    }
  }

  componentDidUpdate = (pp) => {
    if(!pp.userData?.id_user && !this.state.user_data_loaded){
      this.props.userGetData();
      this.setState({ user_data_loaded: true });
    }
  }

  render() {
    const { userData } = this.props;
    console.log("userData:", userData);

    return (
      <div className="top-bar">
        <Navbar expand="lg">
          <NavbarBrand href="/rating/dashboard">
            <img
              src={logo}
              alt="Logo"
            />
          </NavbarBrand>
          <Nav navbar className="ms-auto">
            <NavItem>
              <UncontrolledDropdown>
                <DropdownToggle caret nav>
                  <Translate>Welcome</Translate>, {userData?.username}
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem tag="span">
                    <NavLink to="/rating/settings/my_account"><BsPersonCircle className="icon" /> <Translate>My Account</Translate></NavLink>
                  </DropdownItem>
                  <DropdownItem tag="span">
                    <button
                      className="btn btn-link text-danger"
                      onClick={() => {
                        this.props.userLogout();
                        window.$crisp.push(["do", "session:reset"]);
                      }}
                    ><BsBoxArrowRight className="icon" /> <Translate>Logout</Translate></button>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem>
          </Nav>
        </Navbar>
        <ToastContainer />
      </div>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
    user_settings_loading: state.User.get("user_settings_loading"),
  }),
  {
    userLogout,
    userGetData,
  }
)(TopBar);
